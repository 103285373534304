<template>
  <CinemaLink :cinema>
    <CinemaLogo
      :image-object="logo"
      :image-config="appConfig.images.cinemaCard.logo"
      :rounded="ROUNDED_TYPE.FULL"
      class="size-20"
    />
    <div>
      <div class="text-primary text-sm" v-text="t('cinema')" />
      <div class="text-base font-medium" v-text="cinema.name" />
      <div class="text-xs" v-text="getFormattedCinemaAddress(cinema)" />
    </div>
  </CinemaLink>
</template>

<script lang="ts" setup>
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { t } = useI18n()

const appConfig = useAppConfig()
const logo = computed(() =>
  getImage(cinema, 'thumbnailImage', t('cinemaLogo', { cinema: cinema.name }))
)

defineOptions({
  name: 'SearchAutosuggestResultCinema',
})
</script>

<i18n>
de:
  cinema: "Kino"
  cinemaLogo: "Logo von {cinema}"
es:
  cinema: "Cine"
  cinemaLogo: "Logo de {cinema}"
</i18n>
