<template>
  <MovieLink :movie>
    <MoviePosterImage
      :image-object="posterImage"
      :image-config="appConfig.images.moviePage.poster"
      :rounded="ROUNDED_TYPE.LG"
      class="w-20"
    />

    <div>
      <div class="text-primary text-sm" v-text="t('movie')" />
      <div class="text-base font-medium leading-tight" v-text="movie.title" />
      <MovieFacts :movie class="!text-2xs" />
    </div>
  </MovieLink>
</template>

<script lang="ts" setup>
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

const { movie } = defineProps<Props>()
const { t } = useI18n()

const appConfig = useAppConfig()
const posterImage = computed(() =>
  getImage(movie, 'thumbnailImage', t('posterImage', { movie: movie.title }))
)

defineOptions({
  name: 'SearchAutosuggestResultMovie',
})
</script>

<i18n>
de:
  movie: "Film"
  posterImage: "Bild von {movie}"
es:
  movie: "Película"
  posterImage: "Imagen de {movie}"
</i18n>
