export default function useKeyboardNavigation({
  selector,
  initialFocus,
  onEscape,
  onEnter,
}: {
  onEscape?: () => void
  onEnter?: (item: HTMLElement) => void
  selector: string
  initialFocus?: number
}) {
  let currentFocus = initialFocus ?? -1

  function setFocus(index: number) {
    currentFocus = index
  }

  function handleKeyDown(event: KeyboardEvent): void {
    const items = document.querySelectorAll(selector) as NodeListOf<HTMLElement>

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        currentFocus = (currentFocus + 1) % items.length
        items[currentFocus]?.focus()
        break
      case 'ArrowUp':
        event.preventDefault()
        currentFocus = (currentFocus - 1 + items.length) % items.length
        items[currentFocus]?.focus()
        break
      case 'Enter':
        event.preventDefault()
        items[currentFocus]?.click()

        if (onEnter) {
          onEnter(items[currentFocus])
        }
        break
      case 'Escape':
        if (onEscape) {
          onEscape()
        }
        break
    }
  }

  return {
    handleKeyDown,
    setFocus,
  }
}
