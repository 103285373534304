<template>
  <TransitionSlide
    :enter-from="TRANSITION_POSITIONS.LEFT"
    :enter-to="TRANSITION_POSITIONS.CENTER"
    :leave-to="TRANSITION_POSITIONS.LEFT"
    class="z-100"
  >
    <div
      v-if="isOpen"
      class="dark:text-light light:text-dark bg-neutral-light-2 dark:bg-neutral-dark-2 fixed left-0 top-0 z-50 h-[100dvh] w-80 md:hidden md:transition-none"
    >
      <div class="flex flex-col">
        <div
          class="bg-header relative flex h-16 w-full items-center justify-between px-4"
        >
          <PageLogo class="h-6" @click="hideMenu" />
          <Button
            :icon="SVG_ICON.CLOSE"
            :theme="BUTTON_THEME.TRANSPARENT"
            :title="t('btn.close')"
            class="button-header md:hidden"
            @click="hideMenu"
          />
        </div>

        <div
          class="scrollbar-themed flex flex-col overflow-y-auto px-4 py-2 md:!contents"
        >
          <NuxtLink
            v-for="(menuItem, i) in menuItems"
            :key="i"
            :to="menuItem.url"
            :class="linkClasses"
            active-class="text-primary"
            @click="hideMenu"
          >
            {{ menuItem.label }}
          </NuxtLink>

          <NuxtLink
            :to="
              localeRoute({
                name: ROUTE_NAMES.CITIES_PAGE,
              })
            "
            :class="linkClasses"
            @click="hideMenu"
          >
            {{ t('cities') }}
          </NuxtLink>

          <NuxtLink
            :to="
              localeRoute({
                name: ROUTE_NAMES.PERSONS_PAGE,
              })
            "
            :class="linkClasses"
            @click="hideMenu"
          >
            {{ t('persons') }}
          </NuxtLink>

          <Divider :label="account.label" class="mt-2" />

          <NuxtLink
            :to="
              localeRoute({
                name: ROUTE_NAMES.ACCOUNT,
              })
            "
            :class="linkClasses"
            @click="hideMenu"
          >
            {{ account.label }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </TransitionSlide>
  <BackgroundBlur :visible="isOpen" @click.self="hideMenu" />
</template>

<script setup lang="ts">
defineOptions({
  name: 'PageHeaderNavigationSideBar',
})

const { t } = useI18n()
const { menuItems, hideMenu, isOpen } = useMenu()
const localeRoute = useLocaleRoute()

const account = {
  id: '',
  icon: '',
  label: t('account'),
  url: `${t('accountPath')}/`,
  children: [],
}

const linkClasses =
  'hover:bg-neutral-light-2 dark:hover:bg-header px-3 py-2 rounded-md text-base text-black dark:text-on-header font-medium'
</script>

<i18n>
de:
  account: "Konto"
  accountPath: "konto"
  cities: "Städte"
  persons: "Personen"
  btn:
    close: "Schließen"
es:
  account: "Cuenta"
  accountPath: "cuenta"
  cities: "Ciudades"
  persons: "Personas"
  btn:
    close: "Cerrar"
</i18n>
