<template>
  <div
    class="lg:scrollbar-themed lg:max-h-[calc(100vh-20rem)] lg:overflow-y-auto"
  >
    <SearchAutosuggestResultMovie
      v-for="movie in movies"
      :key="movie.id"
      :movie
      :class="cssClasses.link"
      @click="emit('select')"
    />

    <SearchAutosuggestResultCinema
      v-for="cinema in cinemas"
      :key="cinema.id"
      :cinema
      :class="cssClasses.link"
      @click="emit('select')"
    />

    <Divider v-if="cities?.length" :label="t('cities')" class="mt-2" />
    <SearchAutosuggestResultCity
      v-for="city in cities"
      :key="city.id"
      :city
      :class="cssClasses.link"
      @click="emit('select')"
    />

    <div
      v-if="noResults"
      :class="cssClasses.noResults"
      v-text="t('noResults')"
    />
  </div>
</template>

<script setup lang="ts">
import type { SearchTypeEnum } from '#gql/default'

interface Props {
  query: string
  selectedTypes: SearchTypeEnum[]
  classes?: {
    link: string
    noResults: string
  }
}

const props = defineProps<Props>()
const { t } = useI18n()
const emit = defineEmits(['select'])

const variables = computed(() => {
  return {
    query: props.query,
    limit: 15,
    types: props.selectedTypes.length
      ? props.selectedTypes
      : ([
          SEARCH_TYPE_ENUM.MOVIE,
          SEARCH_TYPE_ENUM.CINEMA,
          SEARCH_TYPE_ENUM.CITY,
        ] as SearchTypeEnum[]),
  }
})

const cssClasses = computed(() => ({
  link: [
    props.classes?.link,
    'grid w-full grid-cols-[auto,minmax(0,1fr)] gap-4 px-3 py-2',
    'text-xs text-xs font-medium hover:rounded-md focus:rounded-md md:hover:rounded-none',
  ],
  noResults:
    props.classes?.noResults ??
    'px-6 py-6 text-xs text-black md:px-4 md:py-0 dark:text-light',
}))

const { cities, movies, cinemas, noResults } = await useSearch2({
  operation: 'FetchSearchForAutoSuggest',
  variables,
})

defineOptions({
  name: 'SearchAutosuggestResults',
})
</script>

<i18n>
de:
  cities: "Städte"
  noResults: "Leider gibt es keine Ergebnisse für den eingegebenen Suchbegriff."
es:
  cities: "Ciudades"
  noResults: "Lo sentimos, no hay resultados para la búsqueda introducida."
</i18n>
