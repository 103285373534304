<template>
  <div>
    <PageHeaderNavigationToggle v-model="isOpen" class="md:hidden" />

    <PageHeaderNavigationSideBar />

    <div class="scrollbar-themed hidden overflow-y-auto p-1 md:flex">
      <NuxtLink
        v-for="(menuItem, i) in menuItems"
        :key="i"
        :to="menuItem.url"
        class="button-header button button-sm h-10"
        active-class="text-primary !bg-transparent"
      >
        {{ menuItem.label }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  name: 'PageHeaderNavigation',
})

const { menuItems, isOpen } = useMenu()
</script>
